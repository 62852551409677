import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorMessagePipe } from './control-error-message.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { DateAgoPipe } from './date-ago.pipe';

@NgModule({
  declarations: [ControlErrorMessagePipe, CapitalizePipe, DateAgoPipe],
  imports: [CommonModule],
  exports: [ControlErrorMessagePipe, CapitalizePipe, DateAgoPipe],
})
export class PipesModule {}

import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  EMAIL_REGEX,
  Nullable,
  ONLY_LETTERS_AND_NUMBERS,
  ONLY_NUMBER_REGEX,
  REGEX_ONLY_LETTER,
} from '@shared/utils';
import { Logger } from '../../classes';

@Injectable({
  providedIn: 'root',
})
export class CustomSyncValidatorsService {
  private logger = new Logger(CustomSyncValidatorsService.name);
  validateFormGroup(control: AbstractControl): Nullable<ValidationErrors> {
    if (control instanceof FormGroup) {
      const controls = control.controls;
      return Object.keys(controls).reduce<ValidationErrors>((prev, curr) => {
        const control = controls[curr];
        if (control.errors) {
          prev[curr] = control.errors;
        }
        return prev;
      }, {});
    }
    this.logger.warn(
      'validateFormGroup: "control" argument is not instance of FormGroup',
    );
    return null;
  }

  emailRegexValidator(): ValidatorFn {
    return (control: AbstractControl): Nullable<ValidationErrors> => {
      if (!EMAIL_REGEX.test(control.value)) {
        return {
          emailRegex: true,
        };
      }
      return null;
    };
  }

  onlyAlphabeticValidator(options = { nullable: false }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (options.nullable && control.value === null) return null;
      if (!REGEX_ONLY_LETTER.test(control.value)) {
        return {
          alphabeticRegex: true,
        };
      }
      return null;
    };
  }

  onlyNumericValidator(options = { nullable: false }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (options.nullable && control.value === null) return null;
      if (!ONLY_NUMBER_REGEX.test(control.value)) {
        return {
          numberRegex: true,
        };
      }
      return null;
    };
  }

  noSpecialCharactersValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) return null;
      if (!ONLY_LETTERS_AND_NUMBERS.test(control.value)) {
        return {
          noSpecialCharacters: true,
        };
      }
      return null;
    };
  }

  passwordsMustMatch(
    passwordControl: string | AbstractControl,
    compareControl: string | AbstractControl,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errorKey = 'passwordsMustMatch';
      let passwordControlRef = null;
      let passwordConfirmationControlRef = null;

      if (typeof passwordControl === 'string') {
        passwordControlRef =
          control instanceof FormGroup
            ? control.get(passwordControl)
            : control.parent?.get(passwordControl);
      } else {
        passwordControlRef = passwordControl;
      }

      if (typeof compareControl === 'string') {
        passwordConfirmationControlRef =
          control instanceof FormGroup
            ? control.get(compareControl)
            : control.parent?.get(compareControl);
      } else {
        passwordConfirmationControlRef = compareControl;
      }
      if (passwordControlRef && passwordConfirmationControlRef) {
        const err = {
          [errorKey]: true,
        };
        if (passwordControlRef.value !== passwordConfirmationControlRef.value) {
          passwordControlRef.setErrors(
            Object.keys(passwordControlRef.errors || {}).length
              ? { ...passwordControlRef.errors, ...err }
              : err,
          );
          passwordConfirmationControlRef.setErrors(
            Object.keys(passwordConfirmationControlRef.errors || {}).length
              ? { ...passwordConfirmationControlRef.errors, ...err }
              : err,
          );
          control.markAllAsTouched();
          return err;
        } else {
          let passwordControlErrors = passwordControlRef.errors;
          let passwordConfirmationControlErrors =
            passwordConfirmationControlRef.errors;

          if (passwordControlRef.errors) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { passwordsMustMatch, ...rest } = passwordControlRef.errors;
            passwordControlErrors = rest;
          }
          if (passwordConfirmationControlRef.errors) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { passwordsMustMatch, ...rest } =
              passwordConfirmationControlRef.errors;
            passwordConfirmationControlErrors = rest;
          }
          passwordControlRef.setErrors(
            Object.keys(passwordControlErrors || {}).length
              ? passwordControlErrors
              : null,
          );
          passwordConfirmationControlRef.setErrors(
            Object.keys(passwordConfirmationControlErrors || {}).length
              ? passwordConfirmationControlErrors
              : null,
          );
        }
      }
      return null;
    };
  }

  maxFileSize(bytes: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value instanceof FileList) {
        for (const file of value) {
          if (file.size > bytes) {
            return {
              exceedsTheMaxFileSizeAllowed: {
                maxSize: bytes,
                currentSize: file.size,
              },
            };
          }
        }
      }
      return null;
    };
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CreateUserPayload,
  GetByFilterUser,
  GetUserByFilterParams,
  GetUserByIdResponse,
  UpdatePersonPayload,
  UpdatePersonResponse,
  UpdateUserPayload,
  User,
  UserDetailed,
  VerifyIfTheEmailExistsResponse,
} from '../models';
import { Pagination } from 'src/app/core/models';
import { ToastService } from '@core/services';
import { ChangePasswordForFirstLoginPayload } from 'src/app/auth/models';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private userUrl = '/users';
  private baseUrl = environment.apiUrl;

  constructor(private _http: HttpClient, private toastService: ToastService) {}

  private getUrl(url: string): string {
    return this.baseUrl + this.userUrl + url;
  }

  getById(userId: number): Observable<UserDetailed> {
    return this._http
      .get<GetUserByIdResponse>(this.getUrl('/show' + '/' + userId))
      .pipe(map((r) => r.data));
  }

  getByFilter(
    filter: GetUserByFilterParams,
  ): Observable<Pagination<GetByFilterUser>> {
    return this._http.get<Pagination<GetByFilterUser>>(
      this.getUrl('/get-by-filter'),
      {
        params: {
          ...filter,
        },
      },
    );
  }

  create(form: CreateUserPayload): Observable<User> {
    return this._http.post<User>(this.getUrl('/create'), { ...form }).pipe(
      tap(() => {
        this.toastService.showToast('¡Usuario creado exitosamente!', 'success');
      }),
    );
  }

  update(id: number, payload: UpdateUserPayload): Observable<User> {
    return this._http
      .put<User>(this.getUrl(`/update/${id}`), payload)
      .pipe(
        tap(() =>
          this.toastService.showToast(
            '¡Usuario actualizado exitosamente!',
            'success',
          ),
        ),
      );
  }

  updatePerson(form: UpdatePersonPayload): Observable<UpdatePersonResponse> {
    return this._http.put<UpdatePersonResponse>(
      this.getUrl(`/update-person/${form.id}`),
      {
        ...form,
      },
    );
  }

  delete(id: number): Observable<{ data: string }> {
    return this._http
      .delete<{ data: string }>(this.getUrl(`/delete/${id}`))
      .pipe(
        tap(() =>
          this.toastService.showToast(
            '¡Usuario eliminado exitosamente!',
            'success',
          ),
        ),
      );
  }

  verifyIfTheEmailExists(
    email: string,
  ): Observable<VerifyIfTheEmailExistsResponse> {
    return this._http.get<VerifyIfTheEmailExistsResponse>(
      this.getUrl(`/email-exists/${email}`),
    );
  }

  changePasswordForFirstLogin(
    payload: ChangePasswordForFirstLoginPayload,
  ): Observable<any> {
    return this._http.put(this.getUrl(`/nueva-contraseña`), payload);
  }
}

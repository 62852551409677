import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { Nullable } from '../../utils/types';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input()
  public title = '';

  @Input()
  public goBackTo = '';

  @ContentChild('rightContent')
  public rightContent: Nullable<TemplateRef<unknown>> = null;
}

import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Nullable } from '@shared/utils';
import {
  AdvancedFiltersDialogCloseEvent,
  TableToolbarFilters,
} from '../models';

export interface AdvancedFiltersDialogData {
  advancedFiltersTemplate: Nullable<TemplateRef<unknown>>;
  advancedFilters: TableToolbarFilters;
}

@Component({
  selector: 'app-advanced-filters-dialog',
  templateUrl: './advanced-filters-dialog.component.html',
  styleUrls: ['./advanced-filters-dialog.component.scss'],
})
export class AdvancedFiltersDialogComponent {
  public advancedFiltersTemplate: Nullable<TemplateRef<unknown>> = null;
  public advancedFilters: TableToolbarFilters = {};

  constructor(
    private dialogRef: MatDialogRef<
      AdvancedFiltersDialogComponent,
      AdvancedFiltersDialogCloseEvent
    >,
    @Inject(MAT_DIALOG_DATA) private data?: AdvancedFiltersDialogData,
  ) {
    if (this.data) {
      this.advancedFiltersTemplate = this.data.advancedFiltersTemplate;
      this.advancedFilters = this.data.advancedFilters;
    }
  }

  onApply(): void {
    this.dialogRef.close({
      event: 'APPLY',
      advancedFilters: this.advancedFilters,
    });
  }

  onClear(): void {
    for (const key in this.advancedFilters) {
      if (Object.prototype.hasOwnProperty.call(this.advancedFilters, key)) {
        this.advancedFilters[key] = null;
      }
    }
    this.dialogRef.close({
      event: 'CLEAR',
      advancedFilters: this.advancedFilters,
    });
  }
}

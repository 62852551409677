import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export const handledErrorStartsWithKey = 'errorHandled_';

@Pipe({
  name: 'controlErrorMessage',
})
export class ControlErrorMessagePipe implements PipeTransform {
  transform(controlError: ValidationErrors): string {
    const errorKeys: Record<string, string> = {
      required: 'Este campo es requerido.',
      maxlength: `Este campo debe contener máximo ${controlError.value?.requiredLength}.`,
      minlength: `Este campo debe contener minimo ${controlError.value?.requiredLength}.`,
      max: `El valor ingresado no debe ser mayor a ${controlError.value?.max}.`,
      min: `El valor ingresado no debe ser menor a ${controlError.value?.min}.`,
      pattern: 'El valor ingresado no cumple con el formato requerido.',
      email: 'El valor ingresado no es un correo electrónico válido.',
      emailRegex: 'El valor ingresado no es un correo electrónico válido.',
      emailIsAlreadyRegistered: 'El e-mail ya está registrado.',
      differentPassword: 'Repita su nueva contraseña.',
      alphabeticRegex: 'Este campo admite solo carácteres alfabéticos.',
      numberRegex: 'Este campo solo admite carácteres numéricos.',
      exceedsTheMaxFileSizeAllowed: `El tamaño máximo admitido es ${(
        controlError?.value?.maxSize / 1000000
      ).toFixed(1)}MB.`,
      noSpecialCharacters: 'El campo solo admite letras y números.',
    };

    let errorMessage = errorKeys[controlError.key] || 'Este campo es inválido.';

    if (controlError.key?.startsWith(handledErrorStartsWithKey)) {
      errorMessage = controlError.value;
    }

    return errorMessage;
  }
}

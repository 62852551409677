import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeChanges = new Subject<Theme>();
  private isDarkModeActive = new BehaviorSubject<boolean>(this.isDarkMode());
  private isLightModeActive = new BehaviorSubject<boolean>(!this.isDarkMode());
  private localStorageKey = 'theme';

  public themeChanges$ = this.themeChanges.asObservable();
  public isDarkModeActive$ = this.isDarkModeActive.asObservable();
  public isLightModeActive$ = this.isLightModeActive.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.isDarkModeActive$.subscribe((value) => {
      if (value) this.isLightModeActive.next(false);
      else this.isLightModeActive.next(true);
    });
  }

  activeDarkMode(): void {
    const nextTheme = Theme.DARK;
    this.document.body.classList.add('dark-mode');
    this.saveThemeToLocalStorage(nextTheme);
    this.themeChanges.next(nextTheme);
    this.isDarkModeActive.next(true);
  }

  activeLightMode(): void {
    const nextTheme = Theme.LIGHT;
    this.document.body.classList.remove('dark-mode');
    this.saveThemeToLocalStorage(nextTheme);
    this.themeChanges.next(nextTheme);
    this.isDarkModeActive.next(false);
  }

  loadTheme(): void {
    const theme = localStorage.getItem(this.localStorageKey);
    if (theme === Theme.DARK) {
      this.activeDarkMode();
    } else {
      this.activeLightMode();
    }
  }

  private isDarkMode(): boolean {
    const theme = localStorage.getItem(this.localStorageKey);
    if (theme === Theme.DARK) return true;
    else return false;
  }

  private saveThemeToLocalStorage(value: Theme): void {
    localStorage.setItem(this.localStorageKey, value);
  }
}

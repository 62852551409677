import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, finalize, of, tap } from 'rxjs';
import {
  ChangePasswordPayload,
  LoginPayload,
  LoginResponse,
  SendResetLinkEmailResponse,
} from '../models';
import endpoints from './endpoints';
import { NO_ERROR_HANDLER_CONTEXT } from 'src/app/core/http-contexts';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public apiUrl = environment.apiUrl;
  constructor(private _http: HttpClient) {}

  private setLocalStorageToken(token: string): void {
    localStorage.setItem(environment.localStorageTokenKey, token);
  }

  private removeLocalStorageToken(): void {
    localStorage.removeItem(environment.localStorageTokenKey);
  }

  login(payload: LoginPayload): Observable<LoginResponse> {
    return this._http
      .post<LoginResponse>(endpoints.login, payload)
      .pipe(tap(({ access_token }) => this.setLocalStorageToken(access_token)));
  }

  sendResetLinkEmail(email: string): Observable<SendResetLinkEmailResponse> {
    return this._http.post<SendResetLinkEmailResponse>(
      endpoints.sendResetLinkEmail,
      {
        email,
      },
    );
  }

  newPassword(payload: ChangePasswordPayload): Observable<unknown> {
    return this._http.post(endpoints.newPassword, payload);
  }

  logout(): Observable<boolean> {
    let obs$ = of<boolean>(true);
    if (localStorage.getItem(environment.localStorageTokenKey)) {
      obs$ = this._http
        .post<boolean>(
          endpoints.logout,
          {},
          { context: NO_ERROR_HANDLER_CONTEXT },
        )
        .pipe(finalize(() => this.removeLocalStorageToken()));
    }
    return obs$;
  }
}

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ThemeService } from './core/services/theme/theme.service';
import { Nullable } from './shared/utils/types';
import { LoadingService } from './core/services/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public title = '';
  public loading = false;
  public favIcon: Nullable<HTMLLinkElement> =
    document.querySelector('#favIcon') || null;
  constructor(
    private themeService: ThemeService,
    private titleService: Title,
    private loadingService: LoadingService,
  ) {
    this.themeService.loadTheme();
    this.titleService.setTitle(environment.title);
    if (this.favIcon) {
      this.favIcon.href = `../assets/images/${environment.favicon}`;
    }
    this.loadingService.isLoading$.subscribe({
      next: (v) => {
        setTimeout(() => {
          this.loading = v;
        });
      },
    });
  }
}

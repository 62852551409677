/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Nullable } from '../../utils/types';

@Directive({
  selector: '[appMatTableResponsive]',
})
export class MatTableResponsiveDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  private onDestroy$ = new Subject<boolean>();

  private thead: Nullable<HTMLTableSectionElement> = null;
  private tbody: Nullable<HTMLTableSectionElement> = null;

  private theadChanged$ = new BehaviorSubject(true);
  private tbodyChanged$ = new Subject<boolean>();

  private theadObserver = new MutationObserver(() =>
    this.theadChanged$.next(true),
  );
  private tbodyObserver = new MutationObserver(() =>
    this.tbodyChanged$.next(true),
  );

  constructor(private table: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.thead = this.table.nativeElement.querySelector('thead');
    this.tbody = this.table.nativeElement.querySelector('tbody');
    if (this.thead && this.tbody) {
      this.theadObserver.observe(this.thead, {
        characterData: true,
        subtree: true,
      });
      this.tbodyObserver.observe(this.tbody, { childList: true });
    }
  }

  ngAfterViewInit(): void {
    /**
     * Set the "data-column-name" attribute for every body row cell, either on
     * thead row changes (e.g. language changes) or tbody rows changes (add, delete).
     */
    combineLatest([this.theadChanged$, this.tbodyChanged$])
      .pipe(
        map(() => {
          const theadHTMLCollection = this.thead?.rows?.item(0)?.children;
          const bodyRowsHTMLCollection = this.tbody?.rows;
          return {
            columnNames: (theadHTMLCollection
              ? Array.from(theadHTMLCollection)
              : []
            ).map((headerCell) => headerCell.textContent || ''),
            rows: Array.from(bodyRowsHTMLCollection || []).map((r) =>
              Array.from(
                (r.children as unknown as HTMLTableCellElement[]) || [],
              ),
            ),
          };
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(({ columnNames, rows }) =>
        rows.forEach((rowCells) =>
          rowCells.forEach((cell) =>
            this.renderer.setAttribute(
              cell,
              'data-column-name',
              columnNames[cell.cellIndex],
            ),
          ),
        ),
      );
  }

  ngOnDestroy(): void {
    this.theadObserver.disconnect();
    this.tbodyObserver.disconnect();

    this.onDestroy$.next(true);
  }
}

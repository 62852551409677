import { PagingParams } from 'src/app/core/services/query-params/models';

// export const getNextPagingParams = (
//   totalNumberOfItems: number,
//   itemsPerPage: number,
//   numberOfItemsAdded = 1,
// ): number => {
//   const totalPages = Math.ceil(totalNumberOfItems / itemsPerPage);
//   let nextPage = totalPages;
//   const nextTotalPages = Math.ceil(
//     (totalNumberOfItems + numberOfItemsAdded) / itemsPerPage,
//   );
//   if (nextTotalPages > totalPages) {
//     nextPage += 1;
//   }
//   return nextPage;
// };

// export const getNextPagingParams = (
//   currentPage: number,
//   totalNumberOfItems: number,
//   itemsPerPage: number,
//   numberOfItemsRemoved = 1,
// ): number => {
//   let nextPage = currentPage;
//   const totalPages = Math.ceil(totalNumberOfItems / itemsPerPage);
//   const nextTotalPages = Math.ceil(
//     (totalNumberOfItems - numberOfItemsRemoved) / itemsPerPage,
//   );
//   const currentPageIsTheLastPage = currentPage === totalPages;
//   if (currentPageIsTheLastPage && nextTotalPages < totalPages) {
//     nextPage -= 1;
//   }
//   return nextPage;
// };

export function getNextPagingParams(action: 'add'): PagingParams;
export function getNextPagingParams(
  action: 'add',
  orderBy: string,
): PagingParams;
export function getNextPagingParams(
  action: 'remove',
  currentPage: number,
  totalItems: number,
  limitPerPage: number,
): PagingParams;

export function getNextPagingParams(
  action: 'add' | 'remove',
  orderBy?: string | number,
  currentPage?: number,
  totalItems?: number,
  limitPerPage?: number,
): PagingParams {
  if (action === 'add') {
    let key = 'id';
    if (typeof orderBy === 'string') {
      key = orderBy;
    }
    return {
      key,
      order: 'desc',
      page: 1,
    };
  }

  if (action === 'remove' && currentPage && totalItems && limitPerPage) {
    const totalPages = Math.ceil(totalItems / limitPerPage);
    return {
      page:
        currentPage === totalPages &&
        Math.ceil((totalItems - 1) / limitPerPage) < totalPages
          ? currentPage - 1
          : currentPage,
    };
  }

  return {};
}

import { User } from 'src/app/features/users/models';

export const getModulePermissions = (
  user: User,
  urlActive: string,
  rolAdmin: string,
): boolean | undefined => {
  const hasPermission = user?.permissions?.some(
    (permission) => `/dashboard${permission.url_module}` === urlActive,
  );
  const isSuper = user.role.some((rol) => rol.name === rolAdmin);
  return isSuper || hasPermission;
};

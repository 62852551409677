import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@store';
import {
  Observable,
  concatMap,
  filter,
  forkJoin,
  map,
  switchMap,
  take,
} from 'rxjs';
import {
  AuthActions,
  selectAuthenticatedUserIsSuperAdmin,
  selectIsAuthenticated,
  selectIsFirstLogin,
  selectIsRefreshingToken,
} from 'src/app/auth/store';

@Injectable()
export class DashboardGuard implements CanActivate {
  private isAuthenticated$ = this.store
    .select(selectIsAuthenticated)
    .pipe(take(1));
  private isFirstLogin$ = this.store.select(selectIsFirstLogin).pipe(take(1));
  private isSuperAdmin$ = this.store
    .select(selectAuthenticatedUserIsSuperAdmin)
    .pipe(take(1));

  constructor(private router: Router, private store: Store<AppState>) {}

  private handleAuthentication(): Observable<boolean | UrlTree> {
    return this.store
      .select(selectIsRefreshingToken)
      .pipe(filter((isRefreshingToken) => !isRefreshingToken))
      .pipe(
        concatMap(() =>
          forkJoin([
            this.isAuthenticated$,
            this.isFirstLogin$,
            this.isSuperAdmin$,
          ]).pipe(
            map(([isAuthenticated, isFirstLogin, isSuperAdmin]) => {
              if (isAuthenticated && isFirstLogin && !isSuperAdmin) {
                return this.router.createUrlTree(['auth', 'first-login']);
              }
              if (isAuthenticated) {
                return true;
              }
              return this.router.createUrlTree(['/auth/login']);
            }),
          ),
        ),
      );
  }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> {
    return this.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return this.handleAuthentication();
        } else {
          this.store.dispatch(AuthActions.refreshToken());
          return this.handleAuthentication();
        }
      }),
    );
  }
}

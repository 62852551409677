import { Component, Input } from '@angular/core';

@Component({
  selector: 'tec-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input()
  public loading = false;
}

import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { UsersService } from 'src/app/features/users/services/users.service';
import { map, mergeMap, of, tap } from 'rxjs';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class CustomAsyncValidatorsService {
  constructor(
    private usersService: UsersService,
    private toastService: ToastService,
  ) {}

  emailIsAlreadyRegisteredValidator(): AsyncValidatorFn {
    const error = {
      emailIsAlreadyRegistered: true,
    };
    return (control: AbstractControl) =>
      of(control.value).pipe(
        tap(() => this.toastService.showInfo('Validando e-mail...')),
        mergeMap((controlValue) =>
          this.usersService.verifyIfTheEmailExists(controlValue).pipe(
            map(({ data }) => {
              if (data.emailIsAlreadyRegistered) {
                this.toastService.showError('El e-mail ya está registrado.');
                return error;
              } else {
                this.toastService.showSuccess('El e-mail está disponible.');
                return null;
              }
            }),
          ),
        ),
      );
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NgControl,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Nullable } from '../../utils/types';

export interface ConfirmationEvent {
  value: string;
  originalEvent: MouseEvent | KeyboardEvent;
}

@Component({
  selector: 'app-input-with-confirmation',
  templateUrl: './input-with-confirmation.component.html',
  styleUrls: ['./input-with-confirmation.component.scss'],
})
export class InputWithConfirmationComponent
  implements ControlValueAccessor, OnDestroy, OnInit
{
  @Input()
  public type = 'text';

  @Input()
  public fieldClassList: Nullable<string> = null;

  @Input()
  public isLoading = false;

  @Input()
  public fieldAppareance = 'outline';

  @Input()
  public hint: Nullable<string> = null;

  @Input()
  public label: Nullable<string> = null;

  @Output()
  public confirm = new EventEmitter<ConfirmationEvent>();

  public isMouseOver = false;
  public isFocused = false;
  public isEditing = false;
  public defaultValue = null;
  public control = new FormControl();
  private destroyed$ = new Subject<void>();
  private validInputTypes = new Set(['text', 'number']);

  constructor(
    @Self() public ngControl: NgControl,
    public containerRef: ControlContainer,
  ) {
    this.ngControl.valueAccessor = this;
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.defaultValue = this.ngControl.value;
    }, 0);
    this.ngControl?.statusChanges?.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (value) => {
        if (value === 'INVALID') {
          this.control.setErrors(this.ngControl.errors);
        }
      },
    });
    if (!this.validInputTypes.has(this.type)) {
      console.warn(
        `Form control '${
          this.ngControl.name
        }' invalid property value 'type' (valid values: ${Array.from(
          this.validInputTypes,
        )})`,
      );
      this.type = 'text';
    }
  }

  onValidatorChange(_: unknown): void {}

  onChange(_: unknown): void {}

  onTouch(): void {}

  onInput(value: string): void {
    this.control.setValue(value);
    this.onTouch();
    this.onChange(this.control.value);
  }

  onInputBlur(): void {
    this.onTouch();
    if (!(this.isMouseOver === this.isFocused)) {
      this.isEditing = false;
    }
  }

  onFocusInput(): void {
    this.onTouch();
    this.isEditing = true;
    this.isFocused = true;
  }

  onConfirm(ev: MouseEvent): void {
    if (!this.isLoading && !this.control.invalid) {
      this.confirm.emit({
        value: this.control.value,
        originalEvent: ev,
      });
      this.defaultValue = this.control.value;
    }
  }

  onCancel(): void {
    this.control.setValue(this.defaultValue, { emitEvent: false });
    this.onChange(this.control.value);
  }

  writeValue(text: string): void {
    if (text) {
      this.control.setValue(text, { emitEvent: false });
    }
  }
  registerOnChange(fn: () => void): void {
    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(fn);
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputWithConfirmationComponent } from './input-with-confirmation.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { IconOrSpinnerModule } from '../icon-or-spinner/icon-or-spinner.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [InputWithConfirmationComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    PipesModule,
    IconOrSpinnerModule,
    ReactiveFormsModule,
  ],
  exports: [InputWithConfirmationComponent],
})
export class InputWithConfirmationModule {}

import { FormGroup, FormControl } from '@angular/forms';

export const validationsForm = (formGroup: FormGroup): void => {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validationsForm(control);
    }
  });
};

export function keys(enun: Record<string, unknown>): Array<string> {
  const keys = Object.keys(enun);
  return keys.slice(keys.length / 2);
}

import { environment } from 'src/environments/environment';

export class Logger {
  constructor(public context: string) {}

  private getArgs(message: string, onlyDevMode: boolean): string[] {
    const args = [`[${this.context}]`];
    if (onlyDevMode) args.push('[ONLY_DEV_MODE]');
    args.push(message);
    return args;
  }

  private baseLog(
    severity: 'log' | 'warn' | 'error' | 'info',
    message: string,
    onlyDevMode: boolean,
  ): void {
    if (onlyDevMode && environment.production) return;
    console[severity](...this.getArgs(message, onlyDevMode));
  }

  log(message: string, onlyDevMode = true): void {
    this.baseLog('log', message, onlyDevMode);
  }
  error(message: string, onlyDevMode = true): void {
    this.baseLog('error', message, onlyDevMode);
  }
  info(message: string, onlyDevMode = true): void {
    this.baseLog('info', message, onlyDevMode);
  }
  warn(message: string, onlyDevMode = true): void {
    this.baseLog('warn', message, onlyDevMode);
  }
}

import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ErrorInterceptor,
  LoadingInterceptor,
  TokenInterceptor,
} from './intenceptors';
import { GlobalErrorHandler } from './error-handlers';
import { DashboardGuard } from './guards/dashboard/dashboard.guard';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    DashboardGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {}

import { Component, Input } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ThemeService } from '@core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent {
  @Input()
  public showIcons = true;

  @Input()
  public classList = '';

  public isDarkThemeActive$: Observable<boolean>;
  public isLightThemeActive$: Observable<boolean>;
  constructor(private themeServices: ThemeService) {
    this.isDarkThemeActive$ = this.themeServices.isDarkModeActive$;
    this.isLightThemeActive$ = this.themeServices.isLightModeActive$;
  }

  activeDarkMode({ checked }: MatSlideToggleChange): void {
    if (checked) {
      this.themeServices.activeDarkMode();
    } else {
      this.themeServices.activeLightMode();
    }
  }
}

import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { RouterStateUrl } from '../shared/utils/router';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from 'src/environments/environment';

import { routerFeatureKey } from './router';
import * as authStore from '../auth/store';

export interface AppState {
  [routerFeatureKey]: RouterReducerState<RouterStateUrl>;
  [authStore.authFeatureKey]: authStore.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [routerFeatureKey]: routerReducer,
  [authStore.authFeatureKey]: authStore.reducer,
};

export function logger(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState | undefined> {
  return function (
    state: AppState | undefined,
    action: Action,
  ): AppState | undefined {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = !environment.production
  ? [logger, storeFreeze]
  : [];

export const APP_EFFECTS = [authStore.AuthEffects];

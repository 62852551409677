import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, concatMap, map, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastService } from '@core/services';
import { UsersService } from 'src/app/features/users/services/users.service';
import { AuthActions } from './auth.actions';
import { LoginResponse } from '../models';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthEffects {
  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      concatMap(({ payload }) =>
        this.authService.login(payload).pipe(
          map((data) => AuthActions.loginSuccess({ data })),
          tap(() => this.router.navigate(['/dashboard'])),
          catchError((error) => of(AuthActions.loginFailure({ error }))),
        ),
      ),
    ),
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      concatMap(() =>
        this.authService.logout().pipe(
          map(() => AuthActions.logoutSuccess()),
          catchError(() => of(AuthActions.logoutFailure())),
        ),
      ),
      tap(() => {
        this.router.navigate(['/auth/login']);
      }),
    ),
  );

  public updateAuthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateAuthenticatedUser),
      concatMap(({ payload }) =>
        this.usersService.updatePerson(payload).pipe(
          map(({ data }) =>
            AuthActions.updateAuthenticatedUserSuccess({ data }),
          ),
          tap(() =>
            this.toastService.showToast(
              '¡Usuario actualizado exitosamente!',
              'success',
            ),
          ),
          catchError((error) =>
            of(AuthActions.updateAuthenticatedUserFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      concatMap(() =>
        this.refreshToken().pipe(
          map((data) => AuthActions.refreshTokenSuccess({ data })),
          catchError((error) => of(AuthActions.refreshTokenFailure({ error }))),
        ),
      ),
    ),
  );

  public restorePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.restorePassword),
      concatMap(({ payload }) =>
        this.authService.newPassword(payload).pipe(
          map(() => AuthActions.restorePasswordSuccess()),
          tap(() => {
            this.router.navigate(['/auth/login']);
            this.toastService.showInfo(
              'Su nueva contraseña ha sido registrada, vuelva a iniciar sesión',
            );
          }),
          catchError(() => of(AuthActions.restorePasswordFailure())),
        ),
      ),
    ),
  );
  public changePasswordForFirstLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePasswordForFirstLogin),
      concatMap(({ payload }) =>
        this.usersService.changePasswordForFirstLogin(payload).pipe(
          map(() => AuthActions.changePasswordForFirstLoginSuccess()),
          tap(() => {
            this.router.navigate(['/auth/login']);
            this.toastService.showInfo(
              'Su nueva contraseña ha sido registrada, vuelva a iniciar sesión',
            );
          }),
          catchError(() =>
            of(AuthActions.changePasswordForFirstLoginFailure()),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private usersService: UsersService,
    private _http: HttpClient,
  ) {}

  refreshToken(): Observable<LoginResponse> {
    return this._http
      .post<LoginResponse>(`${environment.apiUrl}/oauth/refresh-token`, {})
      .pipe(
        tap(({ access_token }) =>
          localStorage.setItem(environment.localStorageTokenKey, access_token),
        ),
      );
  }
}

import { createReducer, on } from '@ngrx/store';
import { Nullable } from '@shared/utils';
import { User } from 'src/app/features/users/models';
import { Token } from '../models';
import { AuthActions } from './auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  updatingUser: boolean;
  loggingIn: boolean;
  refreshingToken: boolean;
  user: Nullable<User>;
  token: Nullable<Token>;
  error: unknown;
}

export const authInitialState: State = {
  updatingUser: false,
  loggingIn: false,
  refreshingToken: false,
  user: null,
  token: null,
  error: null,
};

export const reducer = createReducer(
  authInitialState,

  // LOGIN

  on(AuthActions.login, (state) => ({
    ...state,
    loggingIn: true,
  })),
  on(
    AuthActions.loginSuccess,
    (state, { data: { user, access_token, token_type } }) => ({
      ...state,
      loggingIn: false,
      user,
      error: null,
      token: {
        access_token,
        token_type,
      },
    }),
  ),
  on(AuthActions.loginFailure, (state, { error }) => ({
    ...state,
    error,
    loggingIn: false,
  })),

  // LOGOUT

  on(AuthActions.logout, (state) => state),
  on(AuthActions.logoutSuccess, () => authInitialState),
  on(AuthActions.logoutFailure, () => authInitialState),

  // UPDATE AUTH USER
  on(AuthActions.updateAuthenticatedUser, (state) => ({
    ...state,
    updatingUser: true,
  })),
  on(AuthActions.updateAuthenticatedUserSuccess, (state, { data }) => ({
    ...state,
    user: state.user
      ? { ...state.user, person: { ...state.user.person, ...data } }
      : null,
    updatingUser: false,
  })),
  on(AuthActions.updateAuthenticatedUserFailure, (state) => ({
    ...state,
    updatingUser: false,
  })),
  on(AuthActions.restorePassword, (state) => state),
  on(AuthActions.restorePasswordSuccess, () => authInitialState),
  on(AuthActions.restorePasswordFailure, () => authInitialState),
  on(AuthActions.changePasswordForFirstLogin, (state) => state),
  on(AuthActions.changePasswordForFirstLoginSuccess, () => authInitialState),
  on(AuthActions.changePasswordForFirstLoginFailure, () => authInitialState),

  // Refresh Token
  on(AuthActions.refreshToken, (state) => ({
    ...state,
    refreshingToken: true,
  })),
  on(AuthActions.refreshTokenSuccess, (state, { data }) => ({
    ...state,
    refreshingToken: false,
    user: data.user,
    token: {
      access_token: data.access_token,
      token_type: data.token_type,
    },
  })),
  on(AuthActions.refreshTokenFailure, (state, { error }) => ({
    ...state,
    refreshingToken: false,
    error,
  })),
);

import { HttpErrorResponse } from '@angular/common/http';

export class ErrorHandled implements Error {
  public name: string;
  public message: string;
  public stack?: string;
  public errorFieldMap = new Map<string, string[]>([]);
  public fieldsWithError = new Set<string>([]);
  public originalError: Error | HttpErrorResponse;
  constructor(originalError: Error | HttpErrorResponse) {
    this.name = 'ErrorHandled';
    this.message = originalError.message;
    this.originalError = originalError;
    if (
      originalError instanceof HttpErrorResponse &&
      typeof originalError.error['errors'] === 'object'
    ) {
      const errorsObject = originalError.error['errors'];
      for (const errorKey in errorsObject) {
        if (Object.prototype.hasOwnProperty.call(errorsObject, errorKey)) {
          const element = errorsObject[errorKey];
          this.errorFieldMap.set(errorKey, element);
        }
      }
      this.fieldsWithError = new Set(Array.from(this.errorFieldMap.keys()));
    }
  }

  public get allFieldErrorMessages(): string[] {
    const messages: string[] = [];
    Array.from(this.errorFieldMap.values()).forEach((arr) =>
      messages.push(...arr),
    );
    return messages;
  }

  getAllFieldErrorMessagesConcatenated(concatWith = ' '): string {
    return this.allFieldErrorMessages.join(concatWith);
  }

  setMessage(message: string): this {
    this.message = message;
    return this;
  }
}

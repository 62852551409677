import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;

export default {
  sendResetLinkEmail: `${apiUrl}/oauth/resetpass`,
  newPassword: `${apiUrl}/oauth/reset`,
  logout: `${apiUrl}/oauth/logout`,
  login: `${apiUrl}/oauth/token`,
  refreshToken: `${apiUrl}/oauth/refresh-token`,
};

import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//MAtERIAL
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
//NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// REDUX
import { routerFeatureKey } from '@store/router';
import { reducers, metaReducers, APP_EFFECTS } from '@store';
import { environment } from '../environments/environment';

import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared';

import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { CoreModule } from './core/core.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LoadingModule } from './shared/components/loading/loading.module';

registerLocaleData(localeEs);

const NGRX_IMPORTS = [
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {
      strictActionImmutability: true,
    },
  }),
  StoreRouterConnectingModule.forRoot({
    stateKey: routerFeatureKey,
    routerState: RouterState.Minimal,
  }),
  EffectsModule.forRoot(APP_EFFECTS),
  StoreDevtoolsModule.instrument({
    name: 'Angular PBASE',
    logOnly: !environment.production,
  }),
];

const CUSTOM_MATERIAL_SETTINGS = [
  { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 6000 } },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { appearance: 'outline' },
  },
  { provide: MAT_DATE_LOCALE, useValue: 'es-VE' },
  {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false },
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      ...new MatDialogConfig(),
      minWidth: '250px',
      // maxWidth: '35vw',
    },
  },
  {
    provide: MatPaginatorIntl,
    useValue: {
      changes: new Subject<void>(),

      // For internationalization, the `$localize` function from
      // the `@angular/localize` package can be used.
      firstPageLabel: $localize`Primer página`,
      itemsPerPageLabel: $localize`Items por página:`,
      lastPageLabel: $localize`Última página`,

      // You can set labels to an arbitrary string too, or dynamically compute
      // it through other third-party internationalization libraries.
      nextPageLabel: 'Siguiente',
      previousPageLabel: 'Anterior',

      getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
          return $localize`Pág. 1 de 1`;
        }
        const amountPages = Math.ceil(length / pageSize);
        return $localize`Pág. ${page + 1} de ${amountPages}`;
      },
    },
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    LoadingModule,
    ReactiveFormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    CoreModule,
    ...NGRX_IMPORTS,
  ],
  providers: [
    ...CUSTOM_MATERIAL_SETTINGS,
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

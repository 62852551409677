import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AdvancedFiltersDialogComponent,
  AdvancedFiltersDialogData,
} from './advanced-filters-dialog/advanced-filters-dialog.component';
import { Nullable } from '../../utils/types';
import {
  AdvancedFiltersDialogCloseEvent,
  ApplyFiltersEvent,
  GlobalFilterDef,
  TableToolbarFilters,
} from './models';

@Component({
  selector: 'app-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
})
export class TableToolbarComponent {
  @ContentChild('customButtons')
  public customButtons?: TemplateRef<unknown>;

  /**
   * Esta propiedad determina la alineación entre el grupo de botones y el input de filtro global.
   * @default end
   * @public
   */
  @Input()
  public justifyContent: 'end' | 'between' | 'start' = 'end';

  @Input()
  public addButtonDisabled = false;

  @Input()
  public addButtonLabel = 'Agregar';

  @Input()
  public label_search = '';

  @Input()
  public showDownloadExcelButton = false;

  @Output()
  public downloadExcel = new EventEmitter<PointerEvent>();

  /**
   * Esta propiedad determina si actualmente está ocurriendo una acción asíncrona. Lo cual bloquea los filtros y el botón Agregar.
   * @public
   */
  @Input()
  public loading = false;

  /**
   * Esta propiedad puede ser un objeto en donde la clave es el nombre del filtro a aplicar y el valor el valor del filtro.
   * @public
   */
  @Input()
  public advancedFilters?: TableToolbarFilters;

  @Output()
  public advancedFiltersChange = new EventEmitter<TableToolbarFilters>();

  /**
   * Esta propiedad determina el nombre y el valor que tendrá el filtro de busqueda global.
   * Por defecto el nombre del filtro será `search` y el valor un string en blanco.
   * @public
   */
  @Input()
  public globalFilter: GlobalFilterDef = {
    name: 'search',
    value: '',
  };

  /**
   * Esta propiedad determina si la funcionabilidad del filtro global estará activa. Oculta el input
   * en caso de que el valor sea `false`.
   * @public
   */
  @Input()
  public useGlobalFilter = true;

  /**
   * Esta propiedad determina si debe mostrarse o no el boton Agregar.
   * @public
   */
  @Input()
  public showAddButton = true;

  /**
   * Esta propiedad determina el icono que se mostrará dentro del boton Agregar, debe ser una key de Material Icons.
   * {@link https://fonts.google.com/icons}
   * @public
   */
  @Input()
  public addButtonIcon = 'add';

  /**
   * Esta propiedad determina si debe mostrarse o no el botón de filtros avanzados.
   * @public
   */
  @Input()
  public showAdvancedFiltersButton = true;

  /**
   * Esta propiedad determina el icono que se mostrará dentro del boton de filtros, debe ser una key de Material Icons.
   * {@link https://fonts.google.com/icons}
   * @public
   */
  @Input()
  public advancedFiltersButtonIcon = 'filter_alt';

  /**
   * Este evento se dispara al cliquear el botón Agregar de la toolbar.
   * @public
   */
  @Output()
  public add = new EventEmitter<PointerEvent>();

  /**
   * Este evento se dispara al aplicar/limpiar filtros.
   * @public
   */
  @Output()
  public applyFilters = new EventEmitter<ApplyFiltersEvent>();

  // /**
  //  * Esta propiedad debe hacer referencia un `TemplateRef` para renderizar dentro del dialog
  //  * de filtros avanzados.
  //  * @public
  //  */
  // @Input()
  // public advancedFiltersTemplate: Nullable<TemplateRef<unknown>> = null;

  @ContentChild('advancedFiltersTemplate')
  public advancedFiltersTemplate: Nullable<TemplateRef<unknown>> = null;

  constructor(private dialog: MatDialog) {}

  clearGlobalFilter(): void {
    if (this.globalFilter) {
      this.globalFilter.value = '';
      this.handleFilterEvent();
    }
  }

  applyGlobalFilter(): void {
    if (this.globalFilter) {
      this.globalFilter.value =
        this.globalFilter.value?.trim() || this.globalFilter.value;
      this.handleFilterEvent();
    }
  }

  openAdvancedFilters(): void {
    if (this.advancedFilters) {
      const dialog = this.dialog.open<
        AdvancedFiltersDialogComponent,
        AdvancedFiltersDialogData,
        AdvancedFiltersDialogCloseEvent
      >(AdvancedFiltersDialogComponent, {
        data: {
          advancedFiltersTemplate: this.advancedFiltersTemplate,
          advancedFilters: this.advancedFilters,
        },
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.handleFilterEvent();
        }
      });
    }
  }

  private handleFilterEvent(): ApplyFiltersEvent {
    const applyFiltersEvent: ApplyFiltersEvent = {
      advancedFilters: this.advancedFilters,
      urlSearchParams: new URLSearchParams(),
    };

    if (this.useGlobalFilter && this.globalFilter) {
      applyFiltersEvent['globalFilter'] = {
        name: this.globalFilter.name,
        value: this.globalFilter.value,
      };
      applyFiltersEvent.urlSearchParams.set(
        this.globalFilter.name,
        this.globalFilter.value || '',
      );
    }

    for (const key in this.advancedFilters) {
      if (Object.prototype.hasOwnProperty.call(this.advancedFilters, key)) {
        applyFiltersEvent.urlSearchParams.set(
          key,
          this.advancedFilters[key] || '',
        );
      }
    }
    this.applyFilters.emit(applyFiltersEvent);
    return applyFiltersEvent;
  }
}

export enum EstadoPlanEnum {
  'Deshabilitado',
  'Habilitado',
}

export enum EstadoCivil {
  'Soltero/a',
  'Casado/a',
  'Viudo/a',
}

export enum Genero {
  'M',
  'F',
}

export enum TipoActividadEconomica {
  'Responsable Inscripto',
  'Monotributista',
}

export enum TipoDocumento {
  'DNI',
  'CUIT',
  'CUIL',
}

export enum RoleName {
  SuperAdministrador = 'SUPER ADMINISTRADOR',
  Administrador = 'ADMINISTRADOR',
  AdministradorNivel2 = 'ADMINISTRADOR NIVEL 2',
  AdministradorNivel3 = 'ADMINISTRADOR NIVEL 3',
  Cliente = 'CLIENTE',
  Facturacion = 'FACTURACION',
}

export enum EstadosSolicitud {
  Borrador = 'Borrador',
  Enviada = 'Enviada',
  FechaCargada = 'Fecha cargada',
  FechaRechazada = 'Fecha rechazada',
  FechaConfirmada = 'Fecha confirmada',
  EnProceso = 'En proceso',
  Rechazada = 'Rechazada',
  Finalizada = 'Finalizada',
}

export enum RoleId {
  Administrador = 2,
  AdministradorNivel2 = 3,
  AdministradorNivel3 = 4,
  Cliente = 5,
  SuperAdministrador = 1,
}

export enum PermissionGroup {
  Empresas = 'EMPRESAS',
  Permisos = 'PERMISOS',
  Equipos = 'EQUIPOS',
  Usuarios = 'USUARIOS',
  Facturacion = 'FACTURACION',
  Sistema = 'SISTEMA',
  Comisiones = 'COMISIONES',
}

export enum PermissionName {
  // USUARIOS
  VER_USUARIOS = 'VER USUARIOS',
  EDITAR_USUARIO = 'EDITAR USUARIO',
  CREAR_USUARIO = 'CREAR USUARIO',
  ELIMINAR_USUARIO = 'ELIMINAR USUARIO',

  // PERMISOS
  EDITAR_PERMISOS = 'EDITAR PERMISOS',
  //ESTADISTICAS GENERAL Y POR SERVICIO
  VER_ESTADISTICAS = 'VER ESTADISTICAS GENERAL',
  VER_ESTADISTICAS_POR_SERVICIO = 'VER ESTADISTICA POR SERVICIO',

  // EMPRESAS
  VER_EMPRESAS = 'VER EMPRESAS',
  EDITAR_EMPRESAS = 'EDITAR EMPRESAS',
  CREAR_EMPRESAS = 'CREAR EMPRESAS',

  // LUGARES
  VER_INSTALACIONES = 'VER INSTALACIONES',
  EDITAR_INSTALACIONES = 'EDITAR INSTALACIONES',
  CREAR_INSTALACIONES = 'CREAR INSTALACIONES',

  // EQUIPOS
  VER_EQUIPOS = 'VER EQUIPOS',
  EDITAR_EQUIPOS = 'EDITAR EQUIPOS',
  CREAR_EQUIPOS = 'CREAR EQUIPOS',
  ELIMINAR_EQUIPOS = 'ELIMINAR EQUIPOS',

  // FACTURACION
  VER_FACTURACION = 'VER FACTURACION',
  ADJUNTAR_FACTURA = 'ADJUNTAR FACTURA',
  BUSCAR_INSPECCION_ASOCIADA = 'BUSCAR INSPECCION ASOCIADA',
  ADJUNTAR_RECIBO = 'ADJUNTAR RECIBO',
  DESASOCIAR_REMITO = 'DESASOCIAR REMITO',

  // SOLICITUDES
  VER_SOLICITUDES_DE_SERVICIO = 'VER SOLICITUDES DE SERVICIO',
  CREAR_SOLICITUD_DE_SERVICIO = 'CREAR SOLICITUD DE SERVICIO',

  // COMISIONES
  VER_COMISIONES = 'VER COMISIONES',
  DESCARGAR_COMISIONES = 'DESCARGAR COMISIONES',
}

export enum Iconos {
  Usuarios = 'group',
  Empresas = 'domain',
  Equipos = 'propane',
  Facturacion = 'paid',
  Solicitudes = 'list_alt',
  Comisiones = 'paid',
  EstadisticasPorServicio = 'table_chart',
  Estadisticas = 'bar_chart',
  CalendarioDeVencimientos = 'calendar_today',

}

export enum NombreMenu {
  Usuarios = 'Usuarios',
  Empresas = 'Empresas',
  Equipos = 'Mis Equipos',
  Facturacion = 'Facturación',
  Solicitudes = 'Solicitudes',
  Comisiones = 'Comisiones',
  Estadisticas = 'Estadísticas',
  EstadisticasPorServicio = 'Est. Por Servicio',
  CalendarioDeVencimientos = 'Calendario de Vencimiento',

}

export enum AlcanceDePago {
  Parcial = 'Parcial',
  Total = 'Total',
}
